/* eslint-disable prettier/prettier */
import { LogLevel } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId:  process.env.REACT_APP_AZURE_APP_ID,  
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const hemetricsAPIConfig = {
 //deda5990-536c-45db-855d-0ad97185a559 hemetrics-qa-api
    // 42977feb-90ae-4ef9-a200-464dc237c315 localhost
    scopes: [`api://${process.env.REACT_APP_HEMETRICS_CLIENT_ID}/user_impersonation`] 
};

export const graphConfig = {
  scopes: ['people.read user.readbasic.all user.read'],
  baseURL: 'https://graph.microsoft.com/v1.0'
};
export const emailAPIConfig = {
  scopes: ['api://deda5990-536c-45db-855d-0ad97185a559/email.send'],
  baseURL: process.env.REACT_APP_EMAIL_ENDPOINT
  //baseURL: 'http://localhost:63804'
  //baseURL: 'https://email-qa-api.azurewebsites.net'
};
export const fileShareAPIConfig = {
  scopes: ['api://055bb164-6f0c-4006-848a-a2fb42fce049/user_impersonation'],
  baseURL: process.env.REACT_APP_FS_ENDPOINT
  //scopes: ['api://04b981e8-5201-4efc-863a-aff201202c33/user_impersonation'],  // prod
  //baseURL: 'http://localhost:63804'
  //baseURL: 'https://hemetrics-qa-fileshare.azurewebsites.net'
};
export const notificationAPIConfig = {
  scopes: ['api://b19ceeed-63e5-4677-a263-afbe5c9b1372/user_impersonation'], // QA notification scope
  //scopes: ['api://b05100e8-7d4f-4dab-875f-3c98cb8891ef/user_impersonation'], // notification scope
  // baseURL: 'http://localhost:63804'  // not being used?
};
export const powerBiAPIConfig = {
  baseURL: process.env.REACT_APP_POWERBI_ENDPOINT, // 'https://nybce-powerbi-qa-api.azurewebsites.net'
  scopes: ['api://c19d80c5-a066-49a2-be12-8343c9542dde/fetch_report'],
};
export const boldReportConfig = {
  server: 'https://hemetrics-report.azurewebsites.net'
}
