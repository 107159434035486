/* eslint-disable prettier/prettier */
import { Card, Row, Col, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import WifaService from 'services/wifa-service';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import './wifaHome.css';

const DATE_FORMAT = 'MM-DD-YYYY';
const WIFAPage1 = prop => {
  const [bloodCenterDivision, setBloodCenterDivision] = useState([]);
  const [scenarioData, setScenarioData] = useState([]);
  const [bloodCenterKey, setBloodCenterKey] = useState(0);
  const [bloodCenterCode, setBloodCenterCode] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [wifaSelectedDate, setWifaSelectedDate] = useState(null);
  const [isIBR, setIsIBR] = useState(false);
  const [columnLength, setColumnLength] = useState(4);

  useEffect(() => {
    WifaService.getDivisions().then(divisions => {
      setBloodCenterDivision(divisions);
    });
  }, []);

  const handleDivisionChange = e => {
    setBloodCenterKey(e.target.value);
    if (e.target.value === '503') {
      setIsIBR(true);
      setColumnLength(3);
    } else {
      setIsIBR(false);
      setColumnLength(false);
    }
    // Get BloodCenterCode
    var bloodCenterCodeArray = bloodCenterDivision.filter(x => x.bloodCenterKey == e.target.value);
    if (bloodCenterCodeArray.length > 0) {
      setBloodCenterCode(bloodCenterCodeArray[0].bloodCenterCode);
    }
    
    WifaService.getScenarios('Platelet', e.target.value, wifaSelectedDate).then(scenario => {
      setScenarioData(scenario);
    });
  };

  const handleSubDivisionChange = e => {
    setBloodCenterCode('503');
    console.log(e.target.value);
    WifaService.getScenarios('Platelet', '503', wifaSelectedDate).then(scenario => {
      setScenarioData(scenario);
    });
};

  function transferWifaKeys(e) {
    if (prop.transferWifaKeys) {
      prop.transferWifaKeys(bloodCenterKey, e.target.value, bloodCenterCode, wifaSelectedDate);
    }
  }

  return (
    <>
      <div>
        <Card className="h-100">
          <Card.Body>
            <Row className="mb-3 g-3">
              <h2> WIFA </h2>
              <br />
              <br />
              <p>
                {/* eslint-disable-next-line */}
                The Platelet Supply and Demand What-If-Analysis allows you the
                ability to evaluate various scenarios that may impact inventory
                levels for the projected time period of 14 days. 
                <br />
                Follow this
                wizard to configure each level of incoming and outgoing products
                to mimic and evaluate a given scenario. Upon completing the wizard,
                you have the choice to save the scenario as a primary projection or 
                to save it as a evaluation. Note, any scenario saved as Primary will
                immediately update the 14 Day Projection for all users.
              </p>
               <br />
               <br />
               <br />
            </Row>
            <Row>
            <Form.Group as={Col} lg={columnLength}>
            <Form.Label>Select Date-Time</Form.Label>
            <br/>
            <div>
            <DatePicker
              className="form-control"
              placeholderText="MM-DD-YYYY"
              dateFormat="MM-dd-yyyy"

              //showTimeSelect
              selected={selectedDate}
              onChange={(inputDate) => {
                const guessedTimezone = moment.tz.guess();
                let timezoneDateValue = moment(inputDate).tz(guessedTimezone).format(DATE_FORMAT);
                let dateValue = inputDate ? timezoneDateValue : '';
                setSelectedDate(inputDate);
                setWifaSelectedDate(dateValue);
                if (bloodCenterKey > 0) {
                  WifaService.getScenarios('Platelet', bloodCenterKey, dateValue).then(scenario => {
                    setScenarioData(scenario);
                  });
                }
              }}
            />
            </div>
          </Form.Group>
              <Form.Group as={Col} lg={columnLength}>
                <Form.Label>Select Division</Form.Label>
                <Form.Select
                  name="Action"
                  size="sm"
                  //value={tables}
                  onChange={handleDivisionChange}
                  className="me-2"
                >
                  <option>Select</option>
                  {bloodCenterDivision.map(i => (
                    <option key={i.bloodCenterKey} value={i.bloodCenterKey}>
                      {i.bloodCenterCode}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {isIBR && (
                    <>
                    <Form.Group as={Col} lg={columnLength}>
                    <Form.Label>Select SubDivision</Form.Label>
                    <Form.Select
                      name="Action"
                      size="sm"
                      //value={tables}
                      onChange={handleSubDivisionChange}
                      className="me-2"
                    >
                      <option>Select</option>
                      <option> MBC </option>
                      <option> NCBB </option>
                    </Form.Select>
                  </Form.Group>
                  </>
                  )}
                  
              <Form.Group as={Col} lg={columnLength}>
                <Form.Label>Select base scenario</Form.Label>
                <Form.Select
                  name="Action"
                  size="sm"
                  //value={tables}
                  onChange={transferWifaKeys}
                  className="me-2"
                >
                  <option>Select</option>
                  {scenarioData.map(i => (
                    <option key={i.scenarioKey} value={i.scenarioKey}>
                      {i.scenarioName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer />
        </Card>
      </div>
    </>
  );
};

export default WIFAPage1;
